import { projectTheme } from '../theme/theme.js'
import store from '@/store'

export const theme = {
    data: () => ({
      active: projectTheme,
      availableThemes: [projectTheme],
    }),
    computed: {
        vuetifyThemes() {
          return [
            {
              name: 'default',
              light: {
                primary: this.active.properties['--primary'],
                error: "#F35555",
                warning: "#F68536",
              },
              dark: {
                primary: this.active.properties['--primary'],
                error: "#F35555",
                warning: "#F68536",
              },
            },
            {
              name: 'glass',
              dark: {
                primary: this.active.properties['--primary'],
                success: "#4CAF50",
                error: "#CF0934",
                warning: "#F48535",
                info: "#1757FC",
              },
            }
          ];
        },
      },
    methods: {
        setTheme(activeTheme) {
            this.active = this.availableThemes.find(item => item.name === activeTheme);
            store.dispatch('setEnvTheme', this.active.properties);
            Object.keys(this.active.properties).forEach(property => {
                document.documentElement.style.setProperty(
                    property,
                    this.active.properties[property]
                );
            });
        },
        setVuetifyTheme(activeTheme) {
            const selectedTheme = this.vuetifyThemes.find(t => t.name === activeTheme);
            selectedTheme.dark && Object.keys(selectedTheme.dark).forEach(color => {
                this.$vuetify.theme.themes.dark[color] = selectedTheme.dark[color];
            });
            selectedTheme.light && Object.keys(selectedTheme.light).forEach(color => {
                this.$vuetify.theme.themes.light[color] = selectedTheme.light[color];
            });
            
            this.$set(this.$vuetify.theme.themes, 'name', selectedTheme.name);
        }
    }
}
