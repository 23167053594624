<template>
  <div
    :class="{ 'glass-theme': $vuetify.theme.themes.name === 'glass' }"
    class="layout-wrp"
  >
    <!-- Header -->
    <v-app-bar
      fixed
      color="var(--bgSecondary)"
      :class="{ mobile: $vuetify.breakpoint.smAndDown }"
      ref="headerApp"
    >
      <div class="logo-wrp">
        <router-link :to="{ name: 'Upload' }">
          <div
            class="logo"     
          ></div>
        </router-link>
      </div>

      <div class="btn-wrp d-flex flex-grow-1 justify-end align-center">
        <v-menu left offset-y>
          <template v-slot:activator="{ on }">
            <v-tooltip
              bottom
              :key="'user-configuration'"
            >
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-on="{ ...tooltip, ...on }"
                  icon
                  color="var(--fontColorSecondary)"
                  id="userButton"
                >
                <v-avatar v-if="userLogged && userLogged.photoURL">
                  <img
                    v-if="!returnImgFailed"
                    :src="'mdi-account-circle'"
                    @error="onImgError"
                  >
                  <v-icon v-else :color="'var(--fontColor)'">mdi-account-circle</v-icon>
                  
                </v-avatar>
                  <v-icon v-if="userLogged && !userLogged.photoURL" :color="'var(--fontColor)'">mdi-account-circle</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("userConfiguration") }}</span>
            </v-tooltip>
          </template>
          <v-list class="user-menu text-no-wrap">
            <v-list-item
              id="logoutButton"
              @click="logOut()"
            >
              <v-icon color="var(--fontColor)" left>mdi-exit-to-app</v-icon>
              {{ $t("logout") }}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <div class="overview">
      <DataCard v-if="this.$vuetify.breakpoint.width > 800" class="data-card mr-4" :backgroundImg="backgroundImg"/>
      <div
        class="overviewContainer"
      >
        <div :class="$vuetify.breakpoint.smAndDown ? 'px-4 py-4 ml-4' : 'px-5 py-5'">
          <router-view :key="$route.path"></router-view>
           <component
            :is="moduleComponent"
            class="wrapper-component"
            :notification="notification"
          />  
        </div>    
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { tools } from "@/mixins/tools";
import { theme } from "@/mixins/theme.js";
import {firebaseTools} from "@/mixins/firebase-tools";
import DataCard from "@/components/DataCard.vue"

export default {
  name: "Layout",
  mixins: [tools, theme, firebaseTools],
  components: { DataCard },
  props: {
    backgroundImg: { type: String, default: '' }
  },
  data: () => ({
    notification: null,
    returnImgFailed: false,
  }),
  computed: {
    ...mapState([
      "userLogged",
    ]),
    moduleComponent() {
      console.log(this.$route)
      let comp = null
      if (['Error404', 'Error', 'NotFound', 'NotAllowed'].includes(this.$route.name)) {
        comp = () =>
          import( `@/components/Status`)
      }
      return comp;
    },
  },
  created() {
    if (!this.actualView) {
      const { name } = this.$route;
      this.notification = name.charAt(0).toLowerCase() + name.slice(1);
    }
  },
  methods: {
    onImgError() {
      this.returnImgFailed = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.appearance-menu {
  padding: 0;
  .v-list-item {
    font-size: 14px;
    min-height: 36px;

    &.selected.theme--dark,
    &.selected.theme--light {
      color: var(--primary) !important;
      .v-icon {
        color: var(--primary) !important;
      }
    }

    .v-icon {
      font-size: 20px;
    }
  }
}

.user-menu {
  ::v-deep.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: var(--fontColor) !important;
  }
  .v-list-item .v-icon {
    font-size: 18px;
  }

  a {
    color: var(--fontColor);
    text-decoration: none;
  }
}

.layout-wrp {
  height: 100vh;
  display: flex;
}

.overviewContainer {
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &.menu-open {
    width: calc(100vw - 260px);
    padding-left: 260px;
  }
  & > div {
    margin: 40px;
    height: calc(100% - 80px);
    width: 33%;
    min-width: 450px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 20px;
  }
  & > div > div {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
  }
}
header {
  z-index: 4 !important;

  &.mobile {
    height: 64px !important;
    ::v-deep {
      .v-toolbar__content {
        height: 64px !important;

        .v-btn.v-btn--icon.v-size--default:not(#userButton) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.btn-wrp {
  .v-btn:not(.v-btn--flat):not(.v-btn--text) {
    background-color: transparent;
  }
  .v-btn--icon.v-size--default .v-icon {
    height: 20px;
    font-size: 20px;
    width: 20px;
  }

  #userButton {
    &.v-btn--icon.v-size--default .v-icon {
      height: 35px;
      font-size: 40px;
    }
  }
}

.overview {
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 64px;
}

.close-icon {
  border: 2px solid var(--darkGray);
  &.v-btn--icon.v-size--default .v-icon {
    height: 20px;
    font-size: 20px;
    width: 20px;
  }
}

.glass-theme {
  .close-icon {
    border: 2px solid var(--darkGray) !important;
  }
}
.logo-wrp {
  width: 100%;
  min-width: 150px;
}

.logo {
  width: 100%;
  height: 33px;
  background-position: inherit;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: var(--logo);
}

.breadcrumb-wrp {
  background-color: var(--bgApplicationColor);
  z-index: 2;
}

@media only screen and (max-width: 800px) {
  .logo-wrp {
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    .logo {
      background-image: var(--logoCenter);
    }
  }
}
</style>
