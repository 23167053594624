export default {
  user: "User",
  password: "Password",
  loginUserError: "Invalid username",
  loginPswdError: "Invalid password",
  login: "Login",
  loginWithGoogle: "Login with Google",
  required: "This field is required",
  internalServerError: "Aninternal server error occurred. Contact with your admin",
  unexpectedError: "Sorry, an unexpected error has occurred. If it persists, contact with your admin",
  updateAvailable: 'An update is available',
  cancel: "Cancel",
  save: "Save",
  add: "Add",
  edit: "Edit",
  delete: "Delete",
  update: "Update",
  more: "More",
  next: "Next",
  back: "Back",
  loggedOut: "You have logged out correctly",
  clickToGoLogin: "Go to login",
  goBack: "Go back",
  goMainPage: "Go main page",
  goProcessHistory: "Go to process history",
  close: "Close",
  logout: "Logout",
  userConfiguration: "User configuration",
  email: "Email",
  ok: "Ok",
  openMenu: "Open menu",
  closeMenu: "Close menu",
  "palermo-ui": "NFT",
  // MENU
  Home: "Home",
  Import: "Import file",
  Upload: "Import file",
  History: "History",
  error: {
    sorry: "Sorry",
    notAllowed: "this user is not allowed",
    notFound: "this page does not exist",
    noWorries: "But don't worry, you can always",
    contactAdmin: "Please, contact with your admin",
    problem: "there was a problem...",
    canNotTellWhy: "and I can't tell you why...",
    error: "Sorry, there was a problem."
  },
  importation: {
    success: "Your file has been imported successfully",
    cancelled: "The import has been cancelled successfully. You can import another file",
    error: "Unable to import the file, please try again",
    unauthorized: "Sorry, there was a problem. This user is not allowed",
    notFound: "Sorry, there was a problem. This page does not exist",
  },
  selectFile: "Select the file you want to import",
  yourPc: "Your PC",
  description: "Description",
  asignDescription: "Assign, if you want, a description to the file",
  importFileFromYourPc: "Import file from your PC",
  clickOrDragToImport: "Click or drag to import",
  import: "Import",
  importFile: "Import file",
  youMustSelectAnImportOption: "You must select an import option",
  youMustSelectAtLeastOneFileToImport: "You must select at least one file to import",
  fileImporting: "Your file is being imported",
  ostrich: {
    name: 'Ostrich',
    description: 'It is the largest and heaviest of the birds that still exist; It can reach 3 meters in height and weigh about 180 kg...',
  },
  blueWhale: {
    name: 'Blue whale',
    description: 'Is the largest known animal to ever inhabit the Earth. These majestic marine mammals dominate the oceans with their 30 meters in length and up to 180 tons in weight...',
  },
  crocodile: {
    name: 'Saltwater crocodile',
    description: 'This animal can measure a whopping 7 meters long, and can weigh more than a ton of weight....',
  },
  elephant: {
    name: 'Elephant',
    description: 'The ears of the elephants are not only used to listen to the calls of their relatives. These huge appendages also radiate excess heat to cool down when temperatures get too high...',
  },
  hippo: {
    name: 'Hippo',
    description: 'They spend up to 16 hours a day submerged in rivers and lakes to keep their huge bodies warm under the hot African sun. Hippos are good swimmers and can hold their breath under it for up to five minutes...',
  },
  giraffe: {
    name: 'Giraffe',
    description: 'It can eat hundreds of kilograms of leaves a week and needs to travel for kilometers to find enough food...',
  },
  jellyfish: {
    name: 'Lion\'s mane jellyfish',
    description: 'It has its thousands of tentacles impregnated with poison, with which it paralyzes the fish that enter them in order to eat them. However, its poison is not fatal to humans...',
  },
  polarBear: {
    name: 'Polar bear',
    description: 'They have black skin (look at their nose) and it is covered with transparent hair, free of pigments. Each hair scatters and reflects visible light, this makes them appear white, even though they are not...',
  },
  whaleShark: {
    name: 'Whale shark',
    description: 'The largest fish in the sea thanks to its 12 meters or more. Fortunately for most sea creatures (and us!), their favorite food is plankton...',
  },
  turtle: {
    name: 'Leatherback sea turtle',
    description: 'It is the largest sea turtle in the world, reaching a length of 2.3 meters and a weight of more than 600 kilograms...',
  },
  seeMore: 'See more',
};

