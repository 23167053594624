<template>
  <div class="component-container" :class="wrpClasses">
    <div id="fileToUpload" class="d-flex align-center">
      <v-icon>mdi-file-link-outline</v-icon>
      <div class="d-flex flex-column">
        <v-tooltip
          top
          allow-overflow
          :disabled="!isTooltipActivated[`${file.name}Selection`]"
        >
          <template v-slot:activator="{ on }">
            <span v-on="on" :ref="file.name + 'Selection'" class="fileName">{{
              file.name
            }}</span>
          </template>
          <span class="ellipsisTooltip">{{ file.name }}</span>
        </v-tooltip>
        <div class="d-flex">
          <span class="fileInfo fileType">
            {{ selectedFileTypeName }}
          </span>
          <span class="fileInfo size">
            {{ "(" + formatBytes(file.size) + ")" }}
          </span>
        </div>
      </div>
      <v-btn
        class="clearFile"
        @click="clearFile"
        depressed
        plain
        x-small
        min-width="24px"
      >
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileToUpload",
  props: {
    file: { type: File },
    selectedFileTypeName: { type: String },
    wrpClasses: { type: Object },
  },
  data: () => ({
    isTooltipActivated: {},
  }),
  mounted() {
    this.hasEllipsis(this.isTooltipActivated);
  },
  updated() {
    this.hasEllipsis(this.isTooltipActivated);
  },
  methods: {
    hasEllipsis(isTooltipActivated) {
      setTimeout(() => {
        if (this.$refs) {
          Object.keys(this.$refs).forEach((item) => {
            if (this.$refs[item]) {
              if (!Array.isArray(this.$refs[item])) {
                this.$set(
                  isTooltipActivated,
                  item,
                  this.$refs[item].offsetWidth < this.$refs[item].scrollWidth
                );
              } else {
                this.$refs[item].forEach((el) => {
                  this.$set(
                    isTooltipActivated,
                    item,
                    el.offsetWidth < el.scrollWidth
                  );
                });
              }
            }
          });
        }
      }, 100);
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1000;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    clearFile() {
      this.$emit("clearFile");
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  #fileToUpload {
    .clearFile {
      .v-btn__content {
        opacity: 1;
      }
    }
  }
}

.ellipsisTooltip {
  word-break: normal;
  overflow-wrap: anywhere;
}

.component-container {
  #fileToUpload {
    padding: 8px 10px;
    border: solid 1px #ccc;
    border-radius: 10px;
    width: fit-content;
    color: var(--fontColor);
    max-width: 100%;
    > div {
      min-width: 0;
    }
    .mdi-file-link-outline {
      color: var(--fontColor);
    }
    .mdi-close-circle::before {
      font-size: 14px;
      color: var(--fontColor);
    }

    > * {
      padding: 0 5px;
    }
    .fileName {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .fileType {
      font-style: italic;
    }
    .fileInfo {
      font-size: 12px;
    }
    .size {
      font-weight: 500;
      margin-left: 3px;
    }
    button.clearFile {
      padding: 0;
      background-color: transparent;
    }
  }
}

/*Media queries*/
.component-container {
  &.small {
    #fileToUpload {
      .mdi-close-circle::before {
        font-size: 24px;
      }
      .fileName {
        font-size: 12px;
      }
    }
  }
}
</style>

