import store from '@/store'
import i18n from "@/plugins/i18n";
import axios from "axios"

export const tools = {
  methods: {
    navigateTo(name) {
      function isParent(view, viewName) {
        if (view && !view.parent) {
          return false;
        } else if (view.parent.name === viewName) {
          return true;
        } else {
          return isParent(view.parent, viewName)
        }
      }
      this.$router.history.current.name !== name && (!store.state.actualView || !isParent(store.state.actualView, name)) && this.$router.push({ name });
    },
    httpRequest(method, options, notify, returnHttpResponse = false) {
      return axios[method](...options)
        .then(response => {          
          if (response.data.level === "SUCCESS" && !notify)
            return returnHttpResponse ? response : response.data;
          else if (response.data.level === "SUCCESS" && notify) {
            this.handleHttpStatus(response);
            return returnHttpResponse ? response : response.data;
          } else if (response.data.level === "VALIDATION_ERROR" || response.data.level === "VALIDATION_WARNING") {
            return returnHttpResponse ? response : response.data;
          } else this.handleHttpStatus(response);
        })
        .catch(error => this.handleHttpStatus(error))        
    }, 
    downloadFile(url, method, file, params) {  
      return axios({
        url,
        method,
        data:params,       
        responseType: "blob"
      })
      .then(response => {
          if (response) {                    
            const contentDisposition = response.headers["content-disposition"] || "";
            const matches = /filename=([^;]+)/gi.exec(contentDisposition);
            const fileName = file ? file : ( matches && matches[1] || "untitled").trim();
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.download = fileName;
            link.click();
            return response
          }
          else this.handleHttpStatus(response);
      }).catch(async error => {
        if (error.response) {        
          const responseFile = await this.fileReader(error.response.data);
          const data = JSON.parse(responseFile);
          const errorResponse = { ...error, response: { ...error.response, data } };
          this.handleHttpStatus(errorResponse);
        } else {
           this.handleHttpStatus(error);
        }
      })
    },
    handleHttpStatus(httpResponse) {     
      if (httpResponse) {        
        let statusConfig = {
          msg: i18n.t('unexpectedError'),
          color: 'error',
          icon: 'mdi-alert-circle'
        };
       
        if (httpResponse.status === 401) {         
          store.dispatch('setHttpStatus', { ...statusConfig, msg: i18n.t('expiredSession') });
          this.handleInvalidJWT();
        } else if (httpResponse.data && httpResponse.data.msg)
          store.dispatch('setHttpStatus', { ...statusConfig, msg: httpResponse.data.msg });
        else if (httpResponse.level === 'FAIL' && httpResponse.msg)
          store.dispatch('setHttpStatus', { ...statusConfig, msg: httpResponse.msg });
        else
          store.dispatch('setHttpStatus', { ...statusConfig });       
        
      } else if (httpResponse && httpResponse.status && httpResponse.data.msg) {
        if (httpResponse.data.level === 'VALIDATION_WARNING') {
          let actualWarningMessages = [...store.state.warningMessages]
          actualWarningMessages.push({
            msg: httpResponse.data.msg,
            color: "warning",
            icon: "mdi-alert",
            fileError: httpResponse.data.fileError
          });
          store.dispatch("setWarningMessages", actualWarningMessages);
        } else {
          store.dispatch("setHttpStatus", {
            msg: httpResponse.data.msg,
            color: httpResponse.data.level === "SUCCESS" ? "success" : "error",
            icon: httpResponse.data.level === "SUCCESS" ? "mdi-check-circle" : "mdi-alert-circle",
          });
        }
      }
    },
    deepCopyFunction(inObject) {
      let outObject, value, key;

      if (typeof inObject !== "object" || inObject === null) {
        return inObject; // Return the value if inObject is not an object
      }
      // Create an array or object to hold the values
      outObject = Array.isArray(inObject) ? [] : {};
      inObject = JSON.parse(JSON.stringify(inObject));

      for (key in inObject) {
        value = inObject[key];

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = this.deepCopyFunction(value);
      }

      return outObject;
    },
    setActions(actions) {
      let actionsToReturn;
      if (actions && actions.length) {
        actionsToReturn = this.deepCopyFunction(actions);
        actionsToReturn.forEach(action => {
          action.label = i18n.t(action.label);
          if (action.color) action.color = this.envTheme[action.color] ? this.envTheme[action.color] : action.color;
          if (action.textColor) action.textColor = this.envTheme[action.textColor] ? this.envTheme[action.textColor] : action.textColor;
        });
      }
      return actionsToReturn;
    },
  },
}