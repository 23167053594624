<template>
  <div>
    <v-form ref="loginForm" @submit.prevent="doLogin">
      <v-card-text class="pa-0 mb-2">
        <v-text-field
          v-model="user"
          :placeholder="$t('user')"
          name="login"
          id="login"
          :rules="[rules.required]"
        ></v-text-field>

        <v-text-field
          v-model="password"
          :placeholder="$t('password')"
          type="password"
          id="password"
          name="password"
          :rules="[rules.required]"
          autocomplete="off"
        ></v-text-field>

        <div class="v-messages error--text" v-if="errorMessage">
          {{ $t(errorMessage) }}
        </div>

        <div class="app-version mt-2 text-center">
          v{{ $appConfig.CONFIG.VERSION }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          depressed
          rounded
          color="var(--primary)"
          type="submit"
          :loading="loading"
          :disabled="loading"
          class="mt-3"
          :style="{ color: '#FFFFFF' }"
        >
          <v-icon left>mdi-import</v-icon>
          <span>{{ $t("login") }}</span>
        </v-btn>
      </v-card-actions>
    </v-form>
    <!-- <v-card-actions>
        <v-btn
          depressed
          rounded
          color="var(--primary)"
          type="submit"
          @click="loginWithGoogle"
          class="mt-3"
          :style="{ color: '#555555', backgroundColor: '#FFFFFF', border: '1px solid #CCCCCC' }"
        >
           <div
            class="logo"
            :style="{
              'background-image': envTheme['--iconGoogle'],
            }"
          ></div>
          <span class="label-login-google">{{ $t("loginWithGoogle") }}</span>
        </v-btn>
      </v-card-actions> -->
  </div>
</template>

<script>
import { authentication } from "@/mixins/authentication.js";
import { tools } from "@/mixins/tools";
import i18n from "@/plugins/i18n";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { mapActions, mapState } from "vuex";
import {firebaseTools} from "@/mixins/firebase-tools"

export default {
  name: "Login",
  data: () => ({
    loading: false,
    user: null,
    password: null,
    language: null,
    rules: {
      required: (value) => !!value || i18n.t("required"),
    },
    errorMessage: null,
  }),
  computed: {
    ...mapState([
      "envTheme"
    ]),
  },
  methods: {
    ...mapActions(["setShowSnackBar"]),
    doLogin() {
      this.loading = true;
      this.errorMessage = null;

      if (!this.$refs.loginForm.validate()) {
        this.loading = false;
        return;
      }

      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.user, this.password)
        .then((userCredential) => {
          const {
            accessToken = null,
            email = null,
            language = null,
            group = null,
          } = userCredential.user || {};
          this.createUserSession({
            userLogged: {
              ...userCredential.user,
              name: email,
              language: language ? language : this.language,
              group,
            },
            jwt: accessToken,
          });
          this.loading = false;
        })
        .catch((error) => {
          this.errorMessage = this.handleFireBaseRequests(error.code);
          this.loading = false;
        });
    },
    loginWithGoogle() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {          
          const {
            accessToken = null,
            email = null,
            language = null,
            group = null,
          } = result.user || {};
          this.createUserSession({
            userLogged: {
              ...result.user,
              name: email,
              language: language ? language : this.language,
              group,
            },
            jwt: accessToken,
          });
        })
        .catch((error) => {
          this.handleFireBaseRequests(error.code);
        });
    },
  },
  mixins: [authentication, tools, firebaseTools],
  created() {
    this.language = navigator.language || navigator.userLanguage;

    if (this.language.indexOf("-") > -1) {
      this.language = this.language.substring(0, this.language.indexOf("-"));
    }
  },
};
</script>
<style lang="scss">
.logo {
  width: 18px;
  height: 18px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 7px;
}
.label-login-google {
  font-weight: 400;
}

.v-card.login-card {
  .v-btn--loading.v-btn--disabled:not(.v-btn--outlined):not(.v-btn--flat) {
    background-color: var(--primary) !important;;
  }
  .v-text-field {
    color: var(--fontColor);
    &, &:not(.v-input--has-state):hover {
      > .v-input__control > .v-input__slot:before {
        border-color: var(--fontColor);
      }
    }
    input {
      color: var(--fontColor);
      -webkit-text-fill-color: var(--fontColor) !important;
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: var(--bgSecondary);
  box-shadow: inherit !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
</style>
