<template>
  <div class="component-container" :class="wrpClasses">
    <v-btn-toggle
      :color="color"
      id="importSource"
      v-model="selectedSourceModel"
      @change="sourceSelected"
      group
    >
      <v-btn
        value="yourPc"
        outlined
        :color="color"
        rounded
        class="importSourceButton"
      >
        <div class="d-flex flex-column align-center buttonContent">
          <v-icon :color="color">mdi-laptop</v-icon>
          <span class="mt-2 buttonToggleName">{{ $t("yourPc") }}</span>
        </div>
      </v-btn>
      <GoogleDriveButton
        v-if="googleAppId"
        @fileSelected="fileSelectedDrive"
        @canceled="selectedSourceModel = null"
        :developerKey="googleDeveloperKey"
        :clientId="googleClientId"
        :appId="googleAppId"
        :clickIsAvailable="selectedSourceModel === 'drive'"
      >
        <v-btn
          value="drive"
          outlined
          :color="color"
          rounded
          class="importSourceButton"
        >
          <div class="d-flex flex-column align-center buttonContent">
            <v-img src="../../public/img/Google_Drive_logo.png"></v-img>
            <span class="mt-3 buttonToggleName">Drive</span>
          </div>
        </v-btn>
      </GoogleDriveButton>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name: "ImportSourceSelector",
  props: {
    wrpClasses: { type: Object },
    color: { type: String },
    selectedSource: { type: String },
    googleDeveloperKey: {
      type: String,
    },
    googleClientId: {
      type: String,
    },
    googleAppId: { type: String },
  },
  data: () => ({
    selectedSourceModel: null,
  }),
  watch: {
    selectedSource(value) {
      this.selectedSourceModel = value;
    },
  },
  methods: {
    sourceSelected() {
      this.$emit("sourceSelected", this.selectedSourceModel);
    },
    fileSelectedDrive({ fileData, oauthToken }) {
      this.$emit("fileSelectedDrive", { fileData, oauthToken });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  #importSource .googleDrive-container {
    cursor: auto;
  }
}
#importSource {
  .importSourceButton {
    border: 1px solid #ddd;
    border-radius: 10px;
    opacity: 1;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
    background-color: transparent !important;
    margin: 20px 0 0 20px;
    height: 105px;
    width: 105px;

    ::v-deep .v-btn__content {
      height: 100%;
    }

    .buttonToggleName {
      text-transform: none;
      font-weight: 400;
      color: var(--fontColor);
      font-size: 12px;
    }
    .buttonContent {
      margin: 10px 4px 10px 4px;
      i {
        font-size: 61px;
      }
      svg {
        height: 54px;
      }
    }
  }
}

.component-container {
  &.small {
    #importSource {
      .importSourceButton {
        height: 90px;
        width: 90px;
        .buttonContent {
          i {
            font-size: 50px;
          }
          ::v-deep {
            .v-image .v-responsive__content {
              width: 50px !important;
            }
          }
          svg {
            height: 44px;
          }
          .buttonToggleName {
            font-size: 10px;
          }
        }
      }
    }
  }

}
</style>
