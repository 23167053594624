export const animals = {
  avestruz: {
    name: 'ostrich',
    description: '',
    link: 'https://www.nationalgeographic.es/animales/datos-sorprendentes-sobre-las-aves-mas-grandes-del-mundo',
  },
  ballenaAzul: {
    name: 'blueWhale',
    description: '',
    link: 'https://www.nationalgeographic.es/animales/ballena-azul',
  },
  cocodrilo: {
    name: 'crocodile',
    description: '',
    link: 'https://www.nationalgeographic.es/video/tv/el-sigiloso-rapido-y-mortal-ataque-de-un-cocodrilo-desde-distintos-angulos',
  },
  elefante: {
    name: 'elephant',
    description: '',
    link: 'https://www.nationalgeographic.es/animales/18-datos-sobre-el-animal-terrestre-mas-grande-del-mundo-el-elefante',
  },
  hipopotamo: {
    name: 'hippo',
    description: '',
    link: 'https://www.nationalgeographic.com.es/animales/hipopotamo',
  },
  jirafa: {
    name: 'giraffe',
    description: '',
    link: 'https://www.nationalgeographic.es/animales/seis-curiosidades-que-quiza-desconozcas-de-las-jirafas',
  },
  medusa: {
    name: 'jellyfish',
    description: '',
    link: 'https://es.wikipedia.org/wiki/Cyanea_capillata',
  },
  osoPolar: {
    name: 'polarBear',
    description: '',
    link: 'https://www.greenpeace.org/colombia/blog/issues/climayenergia/10-datos-sorprendentes-sobre-los-osos-polares/',
  },
  tiburonBallena: {
    name: 'whaleShark',
    description: '',
      link: 'https://www.nationalgeographic.es/animales/tiburon-ballena',
  },
  tortuga: {
    name: 'turtle',
    description: '',
    link: 'https://www.nationalgeographic.es/tema/contenido/animales/reptiles/tortugas/tortugas-marinas/tortuga-laud',
  }
}