import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userLogged: undefined,
    httpStatus: undefined,
    dataView: undefined,
    actualView: undefined,
    appMenu: undefined,
    envTheme: undefined,
    swUpdateExists: false,
    showSnackBar: undefined,
  },
  mutations: {
    SET_USER_LOGGED(state, userLogged) {
      state.userLogged = userLogged;
    },
    SET_DATA_VIEW(state, dataView) {
      state.dataView = dataView;
    },
    SET_ACTUAL_VIEW(state, actualView) {
      state.actualView = actualView
    },
    SET_APP_MENU(state, appMenu) {
      state.appMenu = appMenu;
    },
    SET_SETS_STATUS(state, setsStatus) {
      state.setsStatus = setsStatus;
    },
    SET_ENV_THEME(state, envTheme) {
      state.envTheme = envTheme;
    },
    SET_SW_UPDATE_EXISTS(state, swUpdateExists) {
      state.swUpdateExists = swUpdateExists;
    },
    SET_SHOW_SNACKBAR(state, showSnackBar) {
      state.showSnackBar = showSnackBar;
    },
    SET_HTTP_STATUS(state, httpStatus) {
      state.httpStatus = httpStatus;
    },
  },
  actions: {
    setUserLogged({ commit }, userLogged) {
      commit('SET_USER_LOGGED', userLogged);
    },
    setDataView({ commit }, dataView) {
      commit('SET_DATA_VIEW', dataView);
    },
    setActualView({ commit }, actualView) {
      if (actualView) {
        commit('SET_ACTUAL_VIEW', actualView);
      }
    },
    setAppMenu({ commit }, appMenu) {
      commit('SET_APP_MENU', appMenu);
    },
    setEnvTheme({ commit }, envTheme) {
      commit('SET_ENV_THEME', envTheme);
    },
    setSwUpdateExists({ commit }, swUpdateExists) {
      commit('SET_SW_UPDATE_EXISTS', swUpdateExists);
    },
    setShowSnackBar({ commit }, showSnackBar) {
      commit('SET_SHOW_SNACKBAR', showSnackBar);
    },
    setHttpStatus({ commit }, httpStatus) {
      commit('SET_HTTP_STATUS', httpStatus);
    },
  }
})