import { initializeApp } from "firebase/app";
import i18n from '@/plugins/i18n';
import { mapActions, mapState } from "vuex";
import { tools } from '@/mixins/tools';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import store from '@/store';
import { getStorage, ref, uploadBytes } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBsedMbNLuOMLLnyoy4DNvi0pGpWb2WYM4",
  authDomain: "palermo-382209.firebaseapp.com",
  projectId: "palermo-382209",
  storageBucket: "palermo-382209.appspot.com",
  messagingSenderId: "721498271588",
  appId: "1:721498271588:web:b0f3cb67ac968074bdd471"
};
initializeApp(firebaseConfig);
const storage = getStorage();

export const firebaseTools = {
  mixins: [tools],
  computed: {
    ...mapState(["actualView"]),
  },
  methods: {
    ...mapActions(["setShowSnackBar", "setUserLogged"]),
    createStorageReference (name) {
      return ref(storage, name);
    },
    uploadFile(storageReference, file, metadata) {
      // 'file' comes from the Blob or File API
      return uploadBytes(storageReference, file, metadata).then((snapshot) => {
        console.log('Uploaded a blob or file!', snapshot)
        return 'finished'

      });
    },
    logOut() {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.navigateTo('Logout');
      }).catch((error) => {
        this.handleFireBaseRequests(error.code)
      });
    },
    subscribeIsUserLogged() {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (!user) {
          this.$router.history.current.name !== 'Login' && this.$router.history.current.name !== 'Logout' && this.navigateTo("Login");
          this.setUserLogged(undefined);
          localStorage.removeItem('userLogged');
          localStorage.removeItem('token');
        }
        else {
          if (this.$router.history.current.name === 'Login')
            this.navigateTo("Upload");
        }
      });
    },
    getCurrentUser () {
      const auth = getAuth();
      return auth.currentUser;
    },
    handleFireBaseRequests(code, element = null, action = null) {
      console.log('handling error')
      switch (code) {
        case 'auth/user-not-found':
          return i18n.t('loginUserError');
        case 'auth/wrong-password':
          return i18n.t('loginPswdError');
        case 'permission-denied':
        case 'storage/unauthorized':
          this.navigateTo('NotAllowed');
          break;
        case 'storage/unauthenticated':
          break;
        case 'error-CRUD':
          store.dispatch("setShowSnackBar", { color: 'error', icon: "mdi-alert-circle", msg: i18n.t(`error.${action}.${store.state.actualView.name}`, {element: element}) });
          break;
        case 'success-CRUD':
          store.dispatch("setShowSnackBar", { color: 'success', icon: "mdi-check-circle", msg: i18n.t(`success.${action}.${store.state.actualView.name}`, {element: element}) });
          break;
        case 'finish-process':
          if(store.state.actualView.name === "ProcessHistory")
            store.dispatch("setShowSnackBar", { color: 'success', icon: "mdi-check-circle", msg: i18n.t('finishProcess')});
         else
            store.dispatch("setShowSnackBar", { color: 'success', icon: "mdi-check-circle", msg: i18n.t('finishProcess'), link: {to: 'ProcessHistory', text: i18n.t('seeFileProcesses')}});
         break;
        default:
          store.dispatch("setShowSnackBar", { color: 'error', icon: "mdi-alert-circle", msg: i18n.t('unexpectedError') });
          break;
      }
    }
  }
}
