<template>
  <div
    v-intersect="{
      handler: onIntersect,
      options: { threshold: [1.0, 1.0, 1.0, 1.0] },
    }"
    ref="importWrp"
    class="page-container"
    :style="{ ...cssProps }"
    :class="wrpClasses"
  >
    <div class="content-container">
      <div
        class="d-flex flex-grow-1 justify-center align-center loader-wrp"
        v-if="loading"
      >
        <v-progress-circular
          :rotate="-90"
          :size="200"
          :width="20"
          :value="progress"
          color="primary"
        >
          {{ progress }}
        </v-progress-circular>
      </div>

      <template v-else>
        <template v-if="!uploadFinished">
          <v-stepper vertical flat>
            <template>
              <v-stepper-step
                :color="this.envTheme['--primary']"
                :step="1"
              >
                {{ $t("selectFile") }}
              </v-stepper-step>

              <v-stepper-content
                class="selectFileStep"
                :step="1"
              >
                <ImportSourceSelector
                  :wrpClasses="wrpClasses"
                  :color="'var(--fontColor)'"
                  :selectedSource="selectedImportSource"
                  @sourceSelected="changedSelectedImportSource"
                  @fileSelectedDrive="fileSelectedDrive"
                />

                <template v-if="errorFileImport">
                  <v-alert
                    :type="'error'"
                    :icon="'mdi-alert-circle'"
                    color="error"
                    >{{ $t(errorFileImport) }}
                  </v-alert>
                </template>
                <div class="import-wrp">
                  <Import
                    v-if="selectedImportSource === 'yourPc'"
                    class="importFileArea"
                    :title="$t('importFileFromYourPc')"
                    :subtitle="$t('clickOrDragToImport')"
                    :icon="'mdi-laptop'"
                    @file="fileSelected"
                  />
                  <FileToUpload
                    v-if="selectedFile"
                    :file="selectedFile"
                    :wrpClasses="wrpClasses"
                    @clearFile="clearFile"
                  />
                </div>
              </v-stepper-content>
            </template>

            <v-stepper-step
              :color="envTheme['--primary']"
              :step="2"
            >
              <div class="d-flex justify-space-between align-center">
                <span>{{ $t("asignDescription") }}</span>
              </div>
            </v-stepper-step>

            <v-stepper-content
              class="selectFileStep"
              :step="1"
              
            >
              <v-form
                v-model="valid"
                ref="descriptionForm"
                @submit.prevent
              >
                <v-col cols="12">
                  <v-text-field
                    v-model="description"
                    dense
                    :label="$t('description')"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-stepper-content>

            
          </v-stepper>
        </template>
        <template v-else>
          <Status :notification="uploadFinished"/>
        </template>
      </template>
    </div>

    <div class="footer">
      <v-btn
        v-if="loading"
        rounded
        outlined
        :color="'var(--fontColor)'"
        class="cancelButton"
        @click="cancelImport()"
      >
        <v-icon left>mdi-close</v-icon>{{ $t("cancel") }} 
      </v-btn>
      <template v-else>
        <v-btn
          v-if="!uploadFinished"
          rounded
          depressed
          :color="envTheme['--primary']"
          @click="importFile()"
        >
          <v-icon left>mdi-cloud-upload</v-icon>{{ $t("import") }}
        </v-btn>
        <v-btn
          v-else
          rounded
          depressed
          :color="envTheme['--primary']"
          @click="goBackToImport()"
        >
          <v-icon left>mdi-cloud-upload</v-icon>{{ $t("importFile") }}
        </v-btn>
      </template>
    </div>
  </div>
</template>

<script>
import { tools } from "@/mixins/tools";
import { firebaseTools } from "@/mixins/firebase-tools.js";
import { mapState, mapActions } from "vuex";
import ImportSourceSelector from "@/components/ImportSourceSelector.vue";
import FileToUpload from "@/components/FileToUpload.vue";
import { theme } from "@/mixins/theme.js";
import { uploadBytesResumable } from "firebase/storage";
import Status from './Status.vue';

// import i18n from "@/plugins/i18n";

export default {
  name: 'ImportDashboard',
  components: { ImportSourceSelector, FileToUpload, Status },
  mixins: [tools, firebaseTools, theme],
  data: () => ({
    googleAppId: "721498271588",
    googleClientId:
      "721498271588-5507u4rsqtoj1hmlav2n09u8v4kcpbgd.apps.googleusercontent.com",
    googleDeveloperKey: "AIzaSyBsedMbNLuOMLLnyoy4DNvi0pGpWb2WYM4",
    selectedFile: null,
    errorFileImport: null,
    isIntersecting: false,
    loading: false,
    currentStep: 1,
    description: undefined,
    valid: false,
    onStep: ["selectFileToImport"],
    selectedImportSource: undefined,
    progress: 0,
    steps: [
      "selectFileToImport",
      "description",
    ],
    defaultSteps: [
      "selectFileToImport",
      "description",
    ],
    uploadTask: undefined,
    uploadFinished: undefined,
  }),
  computed: {
    ...mapState(["envTheme"]),
    cssProps() {
      return {
        "--primary": this.envTheme['--primary'],
        "--height":
          typeof this.height === "number" ? this.height + "px" : (this.height || '100%') ,
      };
    },
    wrpClasses() {
      const importWrpWidth =
        this.isIntersecting &&
        this.$refs &&
        this.$refs.importWrp &&
        this.$refs.importWrp.offsetWidth;
      const vuetifyBreakpoint = this.$vuetify.breakpoint.width;
      const parentWidth = importWrpWidth || vuetifyBreakpoint;

      return {
        small: parentWidth < 499,
        medium: parentWidth >= 500 && parentWidth < 960,
        large: parentWidth >= 960 && parentWidth < 1253,
        xLarge: parentWidth >= 1253,
      };
    },
  },
  watch: {
    selectedFile() {
      if (this.selectedFile) this.errorFileImport = undefined;
    },

  },
  created () {
    const currentUser = this.getCurrentUser()
    console.log(currentUser)
  },
  methods: {
    ...mapActions(["setIsExecuteProcessModalOpen", "setShowSnackBar"]),
    fileSelected(event) {
      if (event) {
        this.errorFileImport = null;
        this.selectedFile = event;
      }
    },
     clearFile() {
      if (this.selectedImportSource === "drive")
        this.selectedImportSource = null;
      this.selectedFile = null;
      const inputFile = document.querySelector(
        ".import-wrp input[type='file']"
      );
      if (inputFile) inputFile.value = "";
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
    importFile() {
      this.showDropDownErrors = false;
      this.showDropDownNamePatterns = false;

      if (!this.selectedImportSource) {
        this.errorFileImport = "youMustSelectAnImportOption";
        return this.currentStep;
      }
      if (!this.selectedFile) {
        this.errorFileImport = "youMustSelectAtLeastOneFileToImport";
        return this.currentStep;
      }
      if (this.fileNamePatternError || this.extensionFileNameError) {
        return this.currentStep;
      }
      
      if (this.selectedFile) {
        this.loading = true;

        this.uploadFileToStorage(this.selectedFile)
      }
    },
    toIsoString(date) {
      const pad = (num) => (num < 10 ? '0' : '') + num;

      return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        '-' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) 
    },
    uploadFileToStorage (file) {
      const { name } = file || 'Untitled'
      const nameWithoutExtension = name.slice(0, name.lastIndexOf('.'))
      const date = this.toIsoString(new Date());
      const extension = name.slice(name.lastIndexOf('.'))
      const nameToSend = `${nameWithoutExtension}_${date}${extension}`;
      /*
        https://firebase.google.com/docs/storage/web/upload-files?authuser=0
        documentation link
      */
      const fileToUploadRef = this.createStorageReference(nameToSend);
      const currentUser = this.getCurrentUser()
      console.log(fileToUploadRef)

      const fileDescription = this.description ?? ''
      const metadata = {
        customMetadata: {
          'description': `${fileDescription}`,
          'user': `${currentUser.email}`,
          'uid': `${currentUser.uid}`,
          'date': `${date}`
        }
      }
      console.log(metadata)

      this.uploadTask = uploadBytesResumable(fileToUploadRef, file, metadata)
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      this.uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          this.progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          // Handle unsuccessful uploads
          console.log('ERROR: ', error)
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              this.handleFireBaseRequests(error.code)
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              console.log('CANCELLED')
              this.uploadFinished = 'cancelled'
              this.loading = false
              break;

            default:
              this.uploadFinished = 'error'
              this.loading = false
              break;
          }
        }, 
        () => {
          // Handle successful uploads on complete
            this.loading = false;
            this.uploadFinished = 'success';
        }
      );
    },
    cancelImport() {
      console.log('cancelling import')
      this.uploadTask.cancel();
    },
    changedSelectedImportSource(selectedSource) {
      this.selectedImportSource = selectedSource;
      this.selectedFile = null;
      this.errorFileImport = null;
      this.showErrorSelectAtLeastOneFilePackage = false;
    },
    async fileSelectedDrive({ fileData, oauthToken }) {
      this.loading = true;
      const fetchOptions = {
        headers: { Authorization: `Bearer ${oauthToken}` },
      };
      const blob = await fetch(
        `https://www.googleapis.com/drive/v3/files/${fileData.id}?alt=media`,
        fetchOptions
      ).then((res) => {
        this.loading = false;
        return res.blob();
      });
      const fileOptions = {
        type: fileData.mimeType,
        lastModified: fileData.lastEditedUtc,
        size: fileData.size,
      };

      this.selectedFile = new File([blob], fileData.name, fileOptions);
    },
    goBackToImport() {
      this.uploadFinished = false;
      this.uploadTask = undefined;
      this.description=undefined;
      this.selectedFile = null;
      this.errorFileImport = null;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .importFileArea + .component-container #fileToUpload {
    margin-top: 20px;
  }

  .selectFileStep {
    .v-stepper__wrapper {
      margin: -20px 0 0 -20px;
    }
  }

  .import-wrp {
    margin: 20px 0 0 20px;
    .dragFile {
      font-weight: 400;
    }
    .clickImport {
      color: #777;
      font-weight: 500;
    }
    .mdi-laptop {
      color: var(--fontColor);
      margin: 27px 0 6px 0;
    }
  }
  .importFileArea {
    margin-bottom: 20px;
  }
  .content-container .v-stepper.v-stepper--vertical {
    min-height: 100%;
    background: transparent;
    .v-stepper__step--complete .v-stepper__label,
    .v-stepper__step--active .v-stepper__label {
      color: var(--fontColor);
    }

    .v-stepper__step--inactive {
      &:not(.v-stepper__step--active):not(.v-stepper__step--complete) .v-stepper__step__step {
        background-color: var(--primary);
      }

      .v-stepper__label {
        color: var(--fontColor);
      }
    }

    .v-input.v-text-field {
      color: var(--fontColor);
       input,.v-label  {
        color: var(--fontColor);
      }
      .v-input__slot::before {
        border-color: inherit;
      }
    }
  }

  .dropArea {
    padding: 0 15px;
    background: transparent !important;
    &, .dragFile, .clickImport {

      color: var(--fontColor);
    }
  }
}
.loader-wrp {
  height: inherit;
  .v-progress-circular {
    margin: 1rem;
    ::v-deep .v-progress-circular__underlay {
      stroke: rgb(254 138 2 / 40%);
    }
  }
  ::v-deep .v-progress-circular__info {
    color: var(--fontColor);
    font-size: 2.5rem;
    &::after {
      content: '%';
    }
  }
}
.selectFileStep {
  .v-alert {
    margin: 20px 0 0 20px;
  }
  ::v-deep {
    .v-alert__icon {
      align-self: center;
    }
  }
}
.downloadButtonWrp {
  button.v-btn {
    letter-spacing: inherit;
    font-size: 14px;
    font-weight: 400;
    border-color: #ccc;
    text-transform: none;
  }
}

#selectAllButton {
  font-weight: 400;
  text-transform: none;
  border-color: #ccc;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: var(--height);
  position: relative;

  .v-alert {
    border-radius: 10px;
  }

  .content-container {
    overflow-y: auto;
    height: inherit;

    .v-stepper__step {
      padding-left: 20px;
    }

    .v-stepper__step:first-child {
      padding-top: 0;
    }

    .v-stepper__step--complete {
      .infoFileType {
        font-size: 14px;
        margin-top: 33px;
        margin-bottom: -48px;
        color: var(--fontColor);
        span {
          color: #777;
        }
      }

      &:first-child + .v-stepper__content {
        padding: 30px 60px 30px 23px;
      }
    }

    .v-stepper--vertical {
      .v-stepper__content {
        margin-left: 32px;
      }

      .v-stepper__content:not(:last-child) {
        border-left: none;
      }
    }
    
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: #333;
    width: 100%;
    height: 76px;
    align-items: center;

    h3 {
      font-size: 20px;
      font-weight: 400;
    }
  }
}

.footer {
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  padding: 20px;

  .cancelButton,
  .backButton {
    border-color: #ccc;
    color: #555;
  }

  .v-btn.v-btn--has-bg {
    color: white;
  }

  button {
    flex: 1;
    text-transform: none;
    font-weight: 400;
    i {
      font-size: 20px;
    }
    + button {
      margin-left: 10px;
    }
  }
}

/*Media queries*/

.page-container {
  &.small {
    .header,
    .footer {
      padding: 16px;
      height: 68px;
    }

    .content-container {
      overflow-y: auto;
      height: inherit;
    }

    .v-stepper.v-stepper--vertical {
      background: transparent;
      .v-stepper__step {
        padding: 16px;
      }
      .v-stepper__content {
        margin-left: 28px;
      }
    }

    .downloadButtonWrp {
      #downloadFileIcon {
        margin: 0;
      }
      #downloadFileText {
        display: none;
      }
      > button#downloadButton {
        height: 36px;
        width: 36px;
        min-width: fit-content;
        padding: 0;
      }
    }
  }

  &.large {
    ::v-deep.dropArea {
      padding: 37px 0;
    }
  }

  &.xLarge {
    ::v-deep.dropArea {
      padding: 62px 0;
    }
  }
}
::v-deep {
  .fileToUpload {
    .clearFile {
      .v-btn__content {
        opacity: 1;
      }
    }
  }
}

.ellipsisTooltip {
  word-break: normal;
  overflow-wrap: anywhere;
}

.component-container {
  .fileToUpload {
    margin-bottom: 1rem;
    padding: 8px 10px;
    border: solid 1px #ccc;
    border-radius: 10px;
    width: fit-content;
    color: var(--primary);
    max-width: 100%;
    > div {
      min-width: 0;
    }
    .mdi-file-link-outline {
      color: var(--primary);
    }
    .mdi-close-circle::before {
      font-size: 14px;
      color: #999999;
    }

    > * {
      padding: 0 5px;
    }
    .fileName {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .fileType {
      font-style: italic;
    }
    .fileInfo {
      font-size: 12px;
    }
    .size {
      font-weight: 500;
      margin-left: 3px;
    }
    button.clearFile {
      padding: 0;
      background-color: transparent !important;
    }
  }
}

/*Media queries*/
.component-container {
  &.small {
    .fileToUpload {
      .mdi-close-circle::before {
        font-size: 24px;
      }
      .fileName {
        font-size: 12px;
      }
    }
  }
}

</style>
