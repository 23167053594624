export default {
  user: "Usuario",
  password: "Contraseña",
  loginUserError: "Usuario incorrecto",
  loginPswdError: "Contraseña incorrecta",
  login: "Entrar",
  loginWithGoogle: "Iniciar sesión con Google",
  required: "Este campo es requerido",
  internalServerError: "Errorinterno del servidor. Contacta con tu administrador",
  unexpectedError: "Lo sentimos, ha ocurrido un error inesperado. Si persiste, contacta con tu administrador.",
  updateAvailable: 'Hay una actualización disponible',
  cancel: "Cancelar",
  save: "Guardar",
  add: "Añadir",
  edit: "Editar",
  delete: "Eliminar",
  update: "Actualizar",
  more: "Más",
  next: "Siguiente",
  back: "Anterior",
  loggedOut: "Has cerrado sesión correctamente",
  clickToGoLogin: "Ir al login",
  goBack: "Volver",
  goMainPage: "Ir a la página principal",
  goProcessHistory: "Ir al histórico de procesos",
  close: "Cerrar",
  logout: "Cerrar sesión",
  userConfiguration: "Configuración de usuario",
  email: "Email",
  ok: "Hecho",
  openMenu: "Abrir menú",
  closeMenu: "Cerrar menú",
  "palermo-ui": "NFT",
  // MENU
  Home: "Inicio",
  Import: "Importar fichero",
  Upload: "Importar fichero",
  History: "Histórico",
  error: {
    sorry: "Lo sentimos",
    notAllowed: "este usuario no está permitido",
    notFound: "esta página no existe",
    noWorries: "Pero no te preocupes, siempre puedes",
    contactAdmin: "Por favor, contacta con tu administrador",
    problem: "hubo un problema...",
    canNotTellWhy: "y no puedo decirte porqué...",
    error: "Lo sentimos, hay un problema."
  },
  importation: {
    success: "Tu fichero se ha importado correctamente",
    cancelled: "La importación se ha cancelado correctamente. Puedes volver a importar otro fichero",
    error: "No se ha podido importar el fichero, vuelve a intentarlo",
    unauthorized: "Lo sentimos, hay un problema. Este usuario no tiene permisos",
    notFound: "Lo sentimos, hay un problema. Esta página no existe",
  },
  selectFile: "Selecciona el fichero que deseas importar",
  yourPc: "Tu PC",
  description: "Descripción",
  asignDescription: "Asigna, si quieres, una descripción al fichero",
  importFileFromYourPc: "Importar fichero desde tu PC",
  clickOrDragToImport: "Click o arrastrar para importar",
  import: "Importar",
  importFile: "Importar fichero",
  youMustSelectAnImportOption: "Debes seleccionar una opción de importación",
  youMustSelectAtLeastOneFileToImport: "Debes seleccionar al menos un archivo para importar",
  fileImporting: "Tu fichero se está importando",
  ostrich: {
    name: 'Avestruz',
    description: 'Es la más grande y la más pesada de las aves que aún existen; puede alcanzar los 3 metros de altura y pesar unos 180 kg...',
  },
  blueWhale: {
    name: 'Ballena azul',
    description: 'Es el animal conocido más grande que jamás haya poblado la Tierra. Estos majestuosos mamíferos marinos dominan los océanos con sus 30 metros de longitud y hasta 180 toneladas de peso...',
  },
  crocodile: {
    name: 'Cocodrilo marino',
    description: 'Este animal puede llegar a medir la friolera de 7 metros de largo, pudiendo pesar más de una tonelada de peso....',
  },
  elephant: {
    name: 'Elefante',
    description: 'Las orejas de los elefantes no solo sirven para escuchar las llamadas de sus parientes. Estos enormes apéndices también irradian el exceso de calor para así refrescarse cuando las temperaturas son demasiado altas...',
  },
  hippo: {
    name: 'Hipopótamo',
    description: 'Pasan hasta 16 horas al día sumergidos en ríos y lagos para mantener la temperatura de sus enormes cuerpos bajo el cálido sol africano. Los hipopótamos son buenos nadadores y pueden aguantar su respiración bajo esta hasta cinco minutos...',
  },
  giraffe: {
    name: 'Jirafa',
    description: 'Puede comer semanalmente cientos de kilogramos de hojas y necesita viajar durante kilómetros para encontrar suficiente comida...',
  },
  jellyfish: {
    name: 'Medusa crin de león',
    description: 'Tiene sus miles de tentáculos impregnados en veneno, con el que paraliza los peces que se introducen en ellos para poder comérselos. Sin embargo, su veneno no es mortal para el ser humano...',
  },
  polarBear: {
    name: 'Oso polar',
    description: 'Tienen la piel negra (mira su nariz) y está cubierta de pelo transparente, libre de pigmentos. Cada cabello dispersa y refleja la luz visible, esto hace que parezcan de color blanco, aunque no lo son...',
  },
  whaleShark: {
    name: 'Tiburón ballena',
    description: 'El pez más grande del mar gracias a sus 12 metros o más. Afortunadamente para la mayoría de las criaturas marinas (¡y para nosotros!), su comida favorita es el plancton...',
  },
  turtle: {
    name: 'Tortuga Laúd',
    description: 'Es la tortuga marina más grande del mundo, alcanzando una longitud de 2,3 metros y un peso de más de 600 kilogramos...',
  },
  seeMore: 'Ver más',
};
