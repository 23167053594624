<template>
  <v-app class="nfq" :style="{'background-image': imgBg}">
    <v-main>
      <div v-if="snackBar" class="snackBarContainer">
        <v-snackbar v-model="snackBar" :color="snackBar.color" :top="true">
          <div class="d-flex justify-center align-center">
            <v-icon color="white" v-if="snackBar.icon" left>{{ snackBar.icon }}</v-icon>
            <span>
              {{snackBar.msg}}
              <router-link v-if="snackBar.link" :to="{ name: snackBar.link.to }">{{snackBar.link.text}}</router-link>
            </span>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn class="btn-snack" icon @click="setShowSnackBar(false)">
                  <v-icon color="white">mdi-close</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t('close') }}</span>
          </v-tooltip>

        </v-snackbar>
      </div>
      <template v-if="swUpdateExists">
        <v-snackbar top :value="swUpdateExists" :timeout="-1" color="info">
          <div>
            <v-icon color="white" left>mdi-information</v-icon>
            <span>{{ $t('updateAvailable') }}</span>
          </div>
         <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn icon color="white" @click="refreshApp">
                  <v-icon color="white">mdi-refresh</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t('update') }}</span>
          </v-tooltip>
        </v-snackbar>
      </template>
      <transition name="fade">
        <router-view :backgroundImg="imgBg"/>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { theme } from "./mixins/theme.js";
import { mapState, mapActions } from "vuex";
import { updateSw }from "./mixins/update-sw.js";
import { firebaseTools }from "./mixins/firebase-tools.js";

export default {
  name: "App",
  data: () => ({
    snackBar: undefined,
    images: ['avestruz.jpg', 'ballenaAzul.jpg', 'cocodrilo.jpg', 'elefante.jpg', 'hipopotamo.jpg', 'jirafa.jpg', 'medusa.jpg', 'osoPolar.jpg', 'tiburonBallena.jpg', 'tortuga.jpg'],
    imgBg: '',
    imgInterval: null
  }),
  computed: {
    ...mapState(["showSnackBar", "swUpdateExists"]),
  },
  methods: {
    ...mapActions(["setShowSnackBar"]),
    randomImage() {
      this.imgBg = `url("/img/bgImages/${this.images[Math.floor(Math.random() * this.images.length)]
      }")`
      return this.imgBg;
    },
  },
  mixins: [ theme, updateSw, firebaseTools],
  created() {
    this.subscribeIsUserLogged();
    let mode = 'light';
    if (localStorage.getItem('appearanceMode')) {
      mode = localStorage.getItem('appearanceMode');
    } else {
      mode = window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light';
      localStorage.setItem('appearanceMode', mode);
    }
    document.body.classList.toggle(`${mode}-mode`);
    let theme = 'default';
    if (mode === 'dark' && localStorage.getItem('appearanceTheme')) {
      theme = localStorage.getItem('appearanceTheme');
    }
    this.setTheme(theme === 'glass' ? 'glass' : this.$appConfig.CONFIG.UI_ENV);
    this.setVuetifyTheme(theme);
    localStorage.setItem('appearanceTheme', theme);
    document.body.classList.toggle(`${theme}-theme`);
    this. imgInterval = setInterval(this.randomImage,30000)
    this.imgBg = this.randomImage()
  },
  watch: {
    showSnackBar() {
      this.snackBar = this.showSnackBar;
    },
    $route() {
      this.$root.$emit("triggerScroll");
    }
  },
  destroyed () {
    this.imgInterval = null
  }
};
</script>

<style lang="scss">
@import "./assets/styles.scss";
</style>
