export const projectTheme = {
    name: "palermo-ui",
    properties: {
      "--primary": "#FE8A02",
        "--primaryRGB": "25, 115, 184",
        "--white": "#FFFFFF",
        "--secondary": "#028484",
        "--light": "#D2A903",
        "--font": "#333",
        "--gray": "#555555",
        "--ligthGray":'#dddddd',
        "--logoAuth": 'url("/img/Logo_NFT_V.svg")',
        "--logo": 'url("/img/Logo_NFT_H.svg")',
        "--logoCenter": 'url("/img/Logo_NFT_SL.svg")',
        "--iconGoogle": 'url("/img/googleIcon.svg")',
        "--loginBottomDark": 'url("/img/BG1-YELLOW.svg")',
        "--loginBottomMedium": 'url("/img/BG2-YELLOW.svg")',
        "--loginBottomLight": 'url("/img/BG3-YELLOW.svg")',
        "--faceImg": 'url("/img/faceYellow.svg")',
        "--faceImgDark": 'url("/img/face_yellow_dark.svg")',
        "--authBgDark": 'url("/img/DARK-YELLOW-SQUARES.svg")',
        "--authBgMedium": 'url("/img/bg.svg")',
        "--authBgLight": 'url("/img/LIGHT-YELLOW-SQUARES.svg")',
    }
};



