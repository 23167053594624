<template>
  <div class="status-wrp">
    {{$t(statusData.description)}}
    <v-icon :color="statusData.color" class="status-icon">{{statusData.icon}}</v-icon>
    <template v-if="!['success', 'cancelled'].includes(notification)">
      <span>{{$t('error.noWorries')}}...</span>
      <v-btn
        rounded
        outlined
        :color="'var(--fontColor)'"
        @click="goBack()"
        class="go-back-btn"
      >
        <v-icon left>mdi-home</v-icon>{{ $t("goMainPage") }}
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  name: "Status",
  props: {
    notification: { type: String, default: 'default' },
  },
  data: () => ({
    types: {
      success: {
        description: 'importation.success',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      error: {
        description: 'importation.error',
        icon: 'mdi-alert-circle',
        color: 'error'
      },
      cancelled: {
        description: 'importation.cancelled',
        icon: 'mdi-information',
        color: 'info'
      },
      unauthorized: {
        description: 'importation.unauthorized',
        icon: 'mdi-alert-circle',
        color: 'error'
      },
      notAllowed: {
        description: 'importation.unauthorized',
        icon: 'mdi-alert-circle',
        color: 'error'
      },
      notFound: {
        description: 'importation.notFound',
        icon: 'mdi-alert-circle',
        color: 'error'
      },
      default: {
        description: 'error.error',
        icon: 'mdi-alert-circle',
        color: 'error'
      }
    },
  }),
  computed: {
    statusData() {
      return this.types[this.notification]
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'Upload' })
    },
  },
};
</script>
<style scoped lang="scss">
.status-wrp {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  text-align: center;
  font-size: 400;
}

.status-icon.v-icon {
  margin-top: 30px;
  height: 200px;
  width: 200px;
  font-size: 200px;
}

.go-back-btn {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  border-color: #ccc;
}
</style>
