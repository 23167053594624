<template>
  <div>
    <div class="auth-wrp d-flex justify-center align-center" >
      <v-card tile class="pa-5 login-card">
        <v-card-title class="pa-0 mb-5">
          <div class="auth-logo" :style="{ backgroundImage: envTheme['--logoAuth']}" ></div>
        </v-card-title>

        <router-view></router-view>
      </v-card>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";

export default {
  name: "LayoutAuth",
  computed: {
    ...mapState(["envTheme"])
  }
};
</script>

<style lang="scss" scoped>
.auth-wrp {
  min-height: 100vh;
  .v-card {
    backdrop-filter: blur(5px);
    color: var(--fontColor);
    width: 300px;
    background: var(--bgSecondary);
    &.theme--dark {
      background: rgba(34, 34, 34, 0.8);
    }
  }
}

.auth-logo {
  width: 100%;
  height: 60px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
