<template>
  <v-card v-if="currentAnimal" class="data-card">
    <v-card-title>{{ $t(`${currentAnimal.name}.name`) }}</v-card-title>
    <v-card-text>
      {{ $t(`${currentAnimal.name}.description`) }}
      <v-btn target="_blank" link :href="currentAnimal.link" text :color="'var(--fontColor)'" width="fit-content">{{ $t('seeMore')}}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { animals } from "../mixins/animals.js"
export default {
  name: "DataCard",
  props: {
    backgroundImg: { type: String, default: '' }
  },
  data: () => ({
    animals,
  }),
  computed: {
    currentAnimal() {
      const name = this.backgroundImg
      const getStart = name.lastIndexOf('/');
      const getEnd = name.lastIndexOf('.');
      return this.animals[name.slice(getStart + 1, getEnd)];
    }
  },
  methods: {
  
  },
};
</script>

<style scoped lang="scss">
.data-card.v-card {
  .v-card__text, .v-card__title {
    padding: 0;
  }
  align-self: flex-end;
  padding: 20px;
  color: var(--fontColor);
  margin: 40px;
  max-width: 500px;
  > .v-card__title {
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 10px;
  }
  > .v-card__text {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: var(--fontColor);
    > .v-btn {
      align-self: flex-end;
      width: fit-content;
      text-decoration: underline;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>

