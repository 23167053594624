<template>
  <div>
    <!-- TABS -->
    <!-- <v-tabs
      class="menu-tabs"
      :class="$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mb-5'"
      v-if="viewTabs && frameType === 'tabs'"
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in viewTabs.tabs"
        :key="tab.name"
        :to="{ name: tab.name }"
        :id="tab.name"
        >{{ $t(tab.name) }}</v-tab
      >
    </v-tabs> -->
     <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { firebaseTools } from "@/mixins/firebase-tools";
import { tools } from "@/mixins/tools";

export default {
  name: "ViewFrame",
  mixins: [ firebaseTools, tools ],
  props: {
    frameType: { type: String },
  },
  data: () => ({
    tab: null,
    viewTabs: undefined,
    notification: null,
  }),
  computed: {
    ...mapState([
      "actualView",
      "envTheme",
    ]),
  },
  methods: {
    iterateTabs(tabs) {
      for (const tab of tabs) {
        if (tab.name === this.$route.name) return true;
      }
    },
    getTabs(view = {}) {
      if (view.parent && view.parent.tabs) this.viewTabs = view.parent;
      else if (view.parent && !view.parent.tabs) this.getTabs(view.parent);
    },
  },
  watch: {
    actualView(newView, oldView) {
      console.log(newView)
      if (newView?.parent.name !== oldView.parent.name) this.tab = 0;
      this.getTabs(this.actualView);
    },
  },
  mounted() {
    this.getTabs(this.actualView);
    if (this.actualView?.parent && this.actualView.parent.tabs) {
      this.tab = this.actualView.parent.tabs.indexOf(this.actualView);
    }
  },
};
</script>
<style lang="scss" scoped>
.name-detail-header {
  color: var(--primary);
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
